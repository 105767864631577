.editor

  @apply text-sm md:text-base
  .footer &
    @apply text-xxs md:text-xs

  h1
    @apply font-extenda text-2xl md:text-3xl lg:text-4xl
    @apply tracking-wider
    @apply mb-[0.25em]

  h2
    @apply font-extenda text-2xl md:text-3xl lg:text-4xl
    @apply tracking-wider
    @apply mb-[0.25em]

  h3
    @apply text-lg md:text-xl

  h4,
  h5
    @apply text-base md:text-lg
    .footer &
      @apply pt-4


  h3,
  h4,
  h5
    @apply font-protokoll
    @apply font-bold
    @apply mb-[0.25em]

  h1,
  h2,
  h3,
  h4,
  h5
    hyphens: auto
    @screen xl
      hyphens: none
    strong
      font-normal

  &-teaser p
    @apply font-bold


  p,
  ol,
  ul
    @apply mb-[1em]
    &:last-child
      @apply mb-0

  a:not(.no-underline)
    @apply decoration-1 underline underline-offset-[0.3em] decoration-transparent transition-all hover:decoration-inherit

  ol,
  ul
    @apply max-w-3xl mx-auto text-left

  ul
    li
      @apply mb-1 pl-6 relative
      &:before
        content: '— '
        @apply absolute left-0


