.grid__1-cols
  @apply md:grid grid-cols-1
.grid__2-cols
  @apply md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3
.grid__3-cols
  @apply md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3

.grid-col-1
  .grid__1-cols &
    @apply col-span-1
  .grid__2-cols &
    @apply col-span-1
  .grid__3-cols &
    @apply col-span-1

.grid-col-2
  .grid__2-cols &
    @apply col-span-1 lg:col-span-2
  .grid__3-cols &
    @apply col-span-1

.grid-col-3
  .grid__2-cols &
    @apply col-span-1
  .grid__3-cols &
    @apply col-span-1
