.button
  @apply py-2.5 px-5 my-[1.5em]
  @apply inline-flex text-center
  @apply text-sm text-white font-bold
  @apply border border-white


.button,
.button-pager-link
  @apply transition-all duration-300
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset
  &:hover
    box-shadow: inset 0 -100px 0 0 #fff

  .background-blue &
    @apply hover:text-blue-500
  .background-green &
    @apply hover:text-green-500
  .background-orange &
    @apply hover:text-orange-500
  .background-purple &
    @apply hover:text-purple-500
  .background-teal &
    @apply hover:text-teal-500
  .background-red &
    @apply hover:text-red-500
  .background-neutral &,
  .background-image &
    @apply hover:text-neutral-500





