.tabs
  @apply p-0

  .invisible
    border: 0
    clip: rect(0 0 0 0)
    height: 1px
    margin: -1px
    overflow: hidden
    padding: 0
    position: absolute
    width: 1px

  // normale tabs
  .vantage-tabs-tabs__list
    @apply mt-4 mb-6 md:mb-8 p-0 pl-6
    @apply xl:flex xl:justify-start items-center overflow-hidden text-ellipsis
    li:first-child a
      @apply pl-0
  .vantage-tabs-tabs__item
    @apply border-b border-b-white_transparent
    @apply xl:border-b-0 xl:border-r xl:border-r-white_transparent last:xl:border-r-0

  .vantage-tabs-tabs__link
    @apply cursor-pointer select-none
    @apply font-bold py-1.5 xl:py-2 px-4 pl-0 xl:pl-4 bg-none block xl:text-center transition-opacity opacity-50
  [aria-selected="true"].vantage-tabs-tabs__link
    @apply opacity-100

  .vantage-tabs-tabs__content
    & > h3
      @apply hidden

  [aria-hidden="true"].vantage-tabs-tabs__content
    @apply hidden
