.background

  &-blue
    @apply bg-blue-300

    .background-100
      @apply bg-blue-100
    .background-200
      @apply bg-blue-200
    .background-300
      @apply bg-blue-300
    .background-400
      @apply bg-blue-400
    .background-500
      @apply bg-blue-500
    .background-600
      @apply bg-blue-600

    .background-left
      @apply bg-blue-100
    .background-header-logo
      @apply after:bg-blue-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-blue-300
    .background-aside
      &,
      &:after
        @apply bg-blue-500
    .background-header
      @apply bg-blue-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-blue-200), var(--c-blue-200) 50%, var(--c-blue-300) 50%, var(--c-blue-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-blue-200), var(--c-blue-200) 33.333333%, var(--c-blue-300) 33.333333%, var(--c-blue-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-blue-200), var(--c-blue-200) 33.333333%, var(--c-blue-300) 33.333333%, var(--c-blue-300) 66.666666%, var(--c-blue-400) 66.666666%, var(--c-blue-400))


  &-green
    @apply bg-green-300

    .background-100
      @apply bg-green-100
    .background-200
      @apply bg-green-200
    .background-300
      @apply bg-green-300
    .background-400
      @apply bg-green-400
    .background-500
      @apply bg-green-500
    .background-600
      @apply bg-green-600

    .background-left
      @apply bg-green-100
    .background-header-logo
      @apply after:bg-green-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-green-300
    .background-aside
      &,
      &:after
        @apply bg-green-500
    .background-header
      @apply bg-green-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-green-200), var(--c-green-200) 50%, var(--c-green-300) 50%, var(--c-green-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-green-200), var(--c-green-200) 33.333333%, var(--c-green-300) 33.333333%, var(--c-green-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-green-200), var(--c-green-200) 33.333333%, var(--c-green-300) 33.333333%, var(--c-green-300) 66.666666%, var(--c-green-400) 66.666666%, var(--c-green-400))


  &-orange
    @apply bg-orange-300

    .background-100
      @apply bg-orange-100
    .background-200
      @apply bg-orange-200
    .background-300
      @apply bg-orange-300
    .background-400
      @apply bg-orange-400
    .background-500
      @apply bg-orange-500
    .background-600
      @apply bg-orange-600

    .background-left
      @apply bg-orange-100
    .background-header-logo
      @apply after:bg-orange-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-orange-300
    .background-aside
      &,
      &:after
        @apply bg-orange-500
    .background-header
      @apply bg-orange-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-orange-200), var(--c-orange-200) 50%, var(--c-orange-300) 50%, var(--c-orange-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-orange-200), var(--c-orange-200) 33.333333%, var(--c-orange-300) 33.333333%, var(--c-orange-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-orange-200), var(--c-orange-200) 33.333333%, var(--c-orange-300) 33.333333%, var(--c-orange-300) 66.666666%, var(--c-orange-400) 66.666666%, var(--c-orange-400))

  &-purple
    @apply bg-purple-300

    .background-100
      @apply bg-purple-100
    .background-200
      @apply bg-purple-200
    .background-300
      @apply bg-purple-300
    .background-400
      @apply bg-purple-400
    .background-500
      @apply bg-purple-500
    .background-600
      @apply bg-purple-600

    .background-left
      @apply bg-purple-100
    .background-header-logo
      @apply after:bg-purple-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-purple-300
    .background-aside
      &,
      &:after
        @apply bg-purple-500
    .background-header
      @apply bg-purple-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-purple-200), var(--c-purple-200) 50%, var(--c-purple-300) 50%, var(--c-purple-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-purple-200), var(--c-purple-200) 33.333333%, var(--c-purple-300) 33.333333%, var(--c-purple-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-purple-200), var(--c-purple-200) 33.333333%, var(--c-purple-300) 33.333333%, var(--c-purple-300) 66.666666%, var(--c-purple-400) 66.666666%, var(--c-purple-400))


  &-teal
    @apply bg-teal-300

    .background-100
      @apply bg-teal-100
    .background-200
      @apply bg-teal-200
    .background-300
      @apply bg-teal-300
    .background-400
      @apply bg-teal-400
    .background-500
      @apply bg-teal-500
    .background-600
      @apply bg-teal-600

    .background-left
      @apply bg-teal-100
    .background-header-logo
      @apply after:bg-teal-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-teal-300
    .background-aside
      &,
      &:after
        @apply bg-teal-500
    .background-header
      @apply bg-teal-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-teal-200), var(--c-teal-200) 50%, var(--c-teal-300) 50%, var(--c-teal-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-teal-200), var(--c-teal-200) 33.333333%, var(--c-teal-300) 33.333333%, var(--c-teal-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-teal-200), var(--c-teal-200) 33.333333%, var(--c-teal-300) 33.333333%, var(--c-teal-300) 66.666666%, var(--c-teal-400) 66.666666%, var(--c-teal-400))


  &-red
    @apply bg-red-300

    .background-100
      @apply bg-red-100
    .background-200
      @apply bg-red-200
    .background-300
      @apply bg-red-300
    .background-400
      @apply bg-red-400
    .background-500
      @apply bg-red-500
    .background-600
      @apply bg-red-600

    .background-left
      @apply bg-red-100
    .background-header-logo
      @apply after:bg-red-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-red-300
    .background-aside
      &,
      &:after
        @apply bg-red-500
    .background-header
      @apply bg-red-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-red-200), var(--c-red-200) 50%, var(--c-red-300) 50%, var(--c-red-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-red-200), var(--c-red-200) 33.333333%, var(--c-red-300) 33.333333%, var(--c-red-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-red-200), var(--c-red-200) 33.333333%, var(--c-red-300) 33.333333%, var(--c-red-300) 66.666666%, var(--c-red-400) 66.666666%, var(--c-red-400))



  &-neutral
    @apply bg-neutral-300

    .background-100
      @apply bg-neutral-100
    .background-200
      @apply bg-neutral-200
    .background-300
      @apply bg-neutral-300
    .background-400
      @apply bg-neutral-400
    .background-500
      @apply bg-neutral-500
    .background-600
      @apply bg-neutral-600

    .background-left
      @apply bg-neutral-100
    .background-header-logo
      @apply after:bg-neutral-600 after:content-[''] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px]
      @screen xl
        @apply after:bg-none after:h-0
    .background-main
      @apply bg-neutral-300
    .background-aside
      &,
      &:after
        @apply bg-neutral-500
    .background-header
      @apply bg-neutral-600

    @screen md
      .background-main
        background-image: linear-gradient(to right, var(--c-neutral-200), var(--c-neutral-200) 50%, var(--c-neutral-300) 50%, var(--c-neutral-300))
    @screen lg
      &.cols-2
        .background-main
          background-image: linear-gradient(to right, var(--c-neutral-200), var(--c-neutral-200) 33.333333%, var(--c-neutral-300) 33.333333%, var(--c-neutral-300))
      &.cols-3
        .background-main
          background-image: linear-gradient(to right, var(--c-neutral-200), var(--c-neutral-200) 33.333333%, var(--c-neutral-300) 33.333333%, var(--c-neutral-300) 66.666666%, var(--c-neutral-400) 66.666666%, var(--c-neutral-400))
