#cookie-modal
  @apply fixed z-50 h-auto top-auto left-auto bottom-4 right-4 md:bottom-4 xl:bottom-8 md:right-4 xl:right-8
  @apply w-[calc(100%-2rem)] md:w-auto
  @apply max-w-[500px]

  .cookie-modal__content
    @apply p-4 md:p-6 pt-3 md:pt-4 m-0 shadow-none

  .cookie-modal__title
    @apply text-base md:text-lg font-bold
    @apply mb-2

  .cookie-modal__text
    @apply mb-4 md:mb-6

  .cookie-modal__title,
  .cookie-modal__text,
  .cookie-modal__label
    .background-orange


  .cookie-modal__option
    @apply flex mb-2


  .cookie-modal__label

  .cookie-modal__checkbox
    @apply border md:border-2 bg-transparent border-white
    border-width: 1px !important

  .cookie-modal__checkbox ~ .cookie-modal__check svg
    @apply hidden
  .cookie-modal__checkbox:checked ~ .cookie-modal__check svg
    @apply block

  .cookie-modal__buttons
    @apply flex gap-4

  .cookie-modal__button
    @apply py-2.5 px-5
    @apply text-sm text-white font-bold
    @apply border border-white
    @apply basis-1/2 block text-center

    &.hide
      @apply hidden


  .cookie-modal__label
    @apply cursor-none


  .cookie-modal__button.primary
    @apply bg-transparent hover:bg-transparent
