@charset "UTF-8";
@font-face {
  font-family: "Protokoll";
  src: url("/assets/fonts/Protokoll-Regular.woff") format("woff"), url("/assets/fonts/Protokoll-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Protokoll";
  src: url("/assets/fonts/Protokoll-Bold.woff") format("woff"), url("/assets/fonts/Protokoll-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Extenda";
  src: url("/assets/fonts/Extenda-30Deca.woff") format("woff"), url("/assets/fonts/Extenda-30Deca.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
:root {
  --vh: 100vh;
  --c-white: #FFFFFF;
  --c-block: #000000;
  --c-neutral-light: #F0F0F4;
  --c-border: #F0F0F4;
  --c-neutral-overlay: rgba(0,0,0,0.1);
  --c-blue-100: #065BEE;
  --c-blue-200: #0343E3;
  --c-blue-300: #012DD6;
  --c-blue-400: #001DC7;
  --c-blue-500: #0013B6;
  --c-blue-600: #000A96;
  --c-green-100: #1F8C33;
  --c-green-200: #177A24;
  --c-green-300: #17661C;
  --c-green-400: #125417;
  --c-green-500: #0A420F;
  --c-green-600: #0A300D;
  --c-orange-100: #FDA112;
  --c-orange-200: #F98613;
  --c-orange-300: #F46B13;
  --c-orange-400: #ED5C17;
  --c-orange-500: #EB540F;
  --c-orange-600: #E84D0A;
  --c-purple-100: #8A45EB;
  --c-purple-200: #6E29DB;
  --c-purple-300: #6321D1;
  --c-purple-400: #591CBF;
  --c-purple-500: #4F17B0;
  --c-purple-600: #4712A1;
  --c-teal-100: #218FA7;
  --c-teal-200: #1A788A;
  --c-teal-300: #125E73;
  --c-teal-400: #0D5063;
  --c-teal-500: #09455A;
  --c-teal-600: #063F56;
  --c-red-100: #E93947;
  --c-red-200: #E02937;
  --c-red-300: #D21D2A;
  --c-red-400: #C41420;
  --c-red-500: #B22719;
  --c-red-600: #9D0913;
  --c-neutral-100: #a7a8aa;
  --c-neutral-200: #97999b;
  --c-neutral-300: #888b8d;
  --c-neutral-400: #75787b;
  --c-neutral-500: #53565a;
  --c-neutral-600: #000000;
}

@tailwind base;
@layer base {}
@tailwind components;
@layer components {}
@tailwind utilities;
@layer utilities {}
.ac-header {
  @apply relative z-0;
}
.ac-header .icon-expand-btn,
.ac-header .icon-expand-btn-hvr {
  @apply right-4 md:right-4;
}
.ac-header .icon-expand-btn-hvr {
  @apply hidden;
}
.ac-header:hover .icon-expand-btn, .is-active .ac-header .icon-expand-btn {
  @apply hidden;
}
.ac-header:hover .icon-expand-btn-hvr, .is-active .ac-header .icon-expand-btn-hvr {
  @apply block;
}
.is-active .ac-header .icon-expand-btn-hvr {
  @apply -rotate-180;
}
.ac-header:focus, .ac-header::-moz-focus-inner {
  border: none;
  outline: none !important;
}

.ac-text {
  @apply pb-8;
}

.ac.is-active + .ac .ac-header {
  @apply border-t-transparent;
}

.background-blue {
  @apply bg-blue-300;
}
.background-blue .background-100 {
  @apply bg-blue-100;
}
.background-blue .background-200 {
  @apply bg-blue-200;
}
.background-blue .background-300 {
  @apply bg-blue-300;
}
.background-blue .background-400 {
  @apply bg-blue-400;
}
.background-blue .background-500 {
  @apply bg-blue-500;
}
.background-blue .background-600 {
  @apply bg-blue-600;
}
.background-blue .background-left {
  @apply bg-blue-100;
}
.background-blue .background-header-logo {
  @apply after:bg-blue-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-blue .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-blue .background-main {
  @apply bg-blue-300;
}
.background-blue .background-aside, .background-blue .background-aside:after {
  @apply bg-blue-500;
}
.background-blue .background-header {
  @apply bg-blue-600;
}
@screen md {
  .background-blue .background-main {
    background-image: linear-gradient(to right, var(--c-blue-200), var(--c-blue-200) 50%, var(--c-blue-300) 50%, var(--c-blue-300));
  }
}
@screen lg {
  .background-blue.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-blue-200), var(--c-blue-200) 33.333333%, var(--c-blue-300) 33.333333%, var(--c-blue-300));
  }
  .background-blue.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-blue-200), var(--c-blue-200) 33.333333%, var(--c-blue-300) 33.333333%, var(--c-blue-300) 66.666666%, var(--c-blue-400) 66.666666%, var(--c-blue-400));
  }
}
.background-green {
  @apply bg-green-300;
}
.background-green .background-100 {
  @apply bg-green-100;
}
.background-green .background-200 {
  @apply bg-green-200;
}
.background-green .background-300 {
  @apply bg-green-300;
}
.background-green .background-400 {
  @apply bg-green-400;
}
.background-green .background-500 {
  @apply bg-green-500;
}
.background-green .background-600 {
  @apply bg-green-600;
}
.background-green .background-left {
  @apply bg-green-100;
}
.background-green .background-header-logo {
  @apply after:bg-green-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-green .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-green .background-main {
  @apply bg-green-300;
}
.background-green .background-aside, .background-green .background-aside:after {
  @apply bg-green-500;
}
.background-green .background-header {
  @apply bg-green-600;
}
@screen md {
  .background-green .background-main {
    background-image: linear-gradient(to right, var(--c-green-200), var(--c-green-200) 50%, var(--c-green-300) 50%, var(--c-green-300));
  }
}
@screen lg {
  .background-green.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-green-200), var(--c-green-200) 33.333333%, var(--c-green-300) 33.333333%, var(--c-green-300));
  }
  .background-green.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-green-200), var(--c-green-200) 33.333333%, var(--c-green-300) 33.333333%, var(--c-green-300) 66.666666%, var(--c-green-400) 66.666666%, var(--c-green-400));
  }
}
.background-orange {
  @apply bg-orange-300;
}
.background-orange .background-100 {
  @apply bg-orange-100;
}
.background-orange .background-200 {
  @apply bg-orange-200;
}
.background-orange .background-300 {
  @apply bg-orange-300;
}
.background-orange .background-400 {
  @apply bg-orange-400;
}
.background-orange .background-500 {
  @apply bg-orange-500;
}
.background-orange .background-600 {
  @apply bg-orange-600;
}
.background-orange .background-left {
  @apply bg-orange-100;
}
.background-orange .background-header-logo {
  @apply after:bg-orange-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-orange .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-orange .background-main {
  @apply bg-orange-300;
}
.background-orange .background-aside, .background-orange .background-aside:after {
  @apply bg-orange-500;
}
.background-orange .background-header {
  @apply bg-orange-600;
}
@screen md {
  .background-orange .background-main {
    background-image: linear-gradient(to right, var(--c-orange-200), var(--c-orange-200) 50%, var(--c-orange-300) 50%, var(--c-orange-300));
  }
}
@screen lg {
  .background-orange.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-orange-200), var(--c-orange-200) 33.333333%, var(--c-orange-300) 33.333333%, var(--c-orange-300));
  }
  .background-orange.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-orange-200), var(--c-orange-200) 33.333333%, var(--c-orange-300) 33.333333%, var(--c-orange-300) 66.666666%, var(--c-orange-400) 66.666666%, var(--c-orange-400));
  }
}
.background-purple {
  @apply bg-purple-300;
}
.background-purple .background-100 {
  @apply bg-purple-100;
}
.background-purple .background-200 {
  @apply bg-purple-200;
}
.background-purple .background-300 {
  @apply bg-purple-300;
}
.background-purple .background-400 {
  @apply bg-purple-400;
}
.background-purple .background-500 {
  @apply bg-purple-500;
}
.background-purple .background-600 {
  @apply bg-purple-600;
}
.background-purple .background-left {
  @apply bg-purple-100;
}
.background-purple .background-header-logo {
  @apply after:bg-purple-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-purple .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-purple .background-main {
  @apply bg-purple-300;
}
.background-purple .background-aside, .background-purple .background-aside:after {
  @apply bg-purple-500;
}
.background-purple .background-header {
  @apply bg-purple-600;
}
@screen md {
  .background-purple .background-main {
    background-image: linear-gradient(to right, var(--c-purple-200), var(--c-purple-200) 50%, var(--c-purple-300) 50%, var(--c-purple-300));
  }
}
@screen lg {
  .background-purple.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-purple-200), var(--c-purple-200) 33.333333%, var(--c-purple-300) 33.333333%, var(--c-purple-300));
  }
  .background-purple.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-purple-200), var(--c-purple-200) 33.333333%, var(--c-purple-300) 33.333333%, var(--c-purple-300) 66.666666%, var(--c-purple-400) 66.666666%, var(--c-purple-400));
  }
}
.background-teal {
  @apply bg-teal-300;
}
.background-teal .background-100 {
  @apply bg-teal-100;
}
.background-teal .background-200 {
  @apply bg-teal-200;
}
.background-teal .background-300 {
  @apply bg-teal-300;
}
.background-teal .background-400 {
  @apply bg-teal-400;
}
.background-teal .background-500 {
  @apply bg-teal-500;
}
.background-teal .background-600 {
  @apply bg-teal-600;
}
.background-teal .background-left {
  @apply bg-teal-100;
}
.background-teal .background-header-logo {
  @apply after:bg-teal-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-teal .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-teal .background-main {
  @apply bg-teal-300;
}
.background-teal .background-aside, .background-teal .background-aside:after {
  @apply bg-teal-500;
}
.background-teal .background-header {
  @apply bg-teal-600;
}
@screen md {
  .background-teal .background-main {
    background-image: linear-gradient(to right, var(--c-teal-200), var(--c-teal-200) 50%, var(--c-teal-300) 50%, var(--c-teal-300));
  }
}
@screen lg {
  .background-teal.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-teal-200), var(--c-teal-200) 33.333333%, var(--c-teal-300) 33.333333%, var(--c-teal-300));
  }
  .background-teal.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-teal-200), var(--c-teal-200) 33.333333%, var(--c-teal-300) 33.333333%, var(--c-teal-300) 66.666666%, var(--c-teal-400) 66.666666%, var(--c-teal-400));
  }
}
.background-red {
  @apply bg-red-300;
}
.background-red .background-100 {
  @apply bg-red-100;
}
.background-red .background-200 {
  @apply bg-red-200;
}
.background-red .background-300 {
  @apply bg-red-300;
}
.background-red .background-400 {
  @apply bg-red-400;
}
.background-red .background-500 {
  @apply bg-red-500;
}
.background-red .background-600 {
  @apply bg-red-600;
}
.background-red .background-left {
  @apply bg-red-100;
}
.background-red .background-header-logo {
  @apply after:bg-red-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-red .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-red .background-main {
  @apply bg-red-300;
}
.background-red .background-aside, .background-red .background-aside:after {
  @apply bg-red-500;
}
.background-red .background-header {
  @apply bg-red-600;
}
@screen md {
  .background-red .background-main {
    background-image: linear-gradient(to right, var(--c-red-200), var(--c-red-200) 50%, var(--c-red-300) 50%, var(--c-red-300));
  }
}
@screen lg {
  .background-red.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-red-200), var(--c-red-200) 33.333333%, var(--c-red-300) 33.333333%, var(--c-red-300));
  }
  .background-red.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-red-200), var(--c-red-200) 33.333333%, var(--c-red-300) 33.333333%, var(--c-red-300) 66.666666%, var(--c-red-400) 66.666666%, var(--c-red-400));
  }
}
.background-neutral {
  @apply bg-neutral-300;
}
.background-neutral .background-100 {
  @apply bg-neutral-100;
}
.background-neutral .background-200 {
  @apply bg-neutral-200;
}
.background-neutral .background-300 {
  @apply bg-neutral-300;
}
.background-neutral .background-400 {
  @apply bg-neutral-400;
}
.background-neutral .background-500 {
  @apply bg-neutral-500;
}
.background-neutral .background-600 {
  @apply bg-neutral-600;
}
.background-neutral .background-left {
  @apply bg-neutral-100;
}
.background-neutral .background-header-logo {
  @apply after:bg-neutral-600 after:content-[""] after:absolute after:top-0 after:left-0 after:w-[calc(100vw-40px)] after:h-[70px] md:after:w-full md:after:h-[90px];
}
@screen xl {
  .background-neutral .background-header-logo {
    @apply after:bg-none after:h-0;
  }
}
.background-neutral .background-main {
  @apply bg-neutral-300;
}
.background-neutral .background-aside, .background-neutral .background-aside:after {
  @apply bg-neutral-500;
}
.background-neutral .background-header {
  @apply bg-neutral-600;
}
@screen md {
  .background-neutral .background-main {
    background-image: linear-gradient(to right, var(--c-neutral-200), var(--c-neutral-200) 50%, var(--c-neutral-300) 50%, var(--c-neutral-300));
  }
}
@screen lg {
  .background-neutral.cols-2 .background-main {
    background-image: linear-gradient(to right, var(--c-neutral-200), var(--c-neutral-200) 33.333333%, var(--c-neutral-300) 33.333333%, var(--c-neutral-300));
  }
  .background-neutral.cols-3 .background-main {
    background-image: linear-gradient(to right, var(--c-neutral-200), var(--c-neutral-200) 33.333333%, var(--c-neutral-300) 33.333333%, var(--c-neutral-300) 66.666666%, var(--c-neutral-400) 66.666666%, var(--c-neutral-400));
  }
}

.base-bigtarget {
  @apply before:content-[""] before:absolute before:top-0 before:right-0 before:bottom-0 before:left-0;
}

.button {
  @apply py-2.5 px-5 my-[1.5em];
  @apply inline-flex text-center;
  @apply text-sm text-white font-bold;
  @apply border border-white;
}

.button,
.button-pager-link {
  @apply transition-all duration-300;
  box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px inset;
}
.button:hover,
.button-pager-link:hover {
  box-shadow: inset 0 -100px 0 0 #fff;
}
.background-blue .button,
.background-blue .button-pager-link {
  @apply hover:text-blue-500;
}
.background-green .button,
.background-green .button-pager-link {
  @apply hover:text-green-500;
}
.background-orange .button,
.background-orange .button-pager-link {
  @apply hover:text-orange-500;
}
.background-purple .button,
.background-purple .button-pager-link {
  @apply hover:text-purple-500;
}
.background-teal .button,
.background-teal .button-pager-link {
  @apply hover:text-teal-500;
}
.background-red .button,
.background-red .button-pager-link {
  @apply hover:text-red-500;
}
.background-neutral .button, .background-image .button,
.background-neutral .button-pager-link,
.background-image .button-pager-link {
  @apply hover:text-neutral-500;
}

#cookie-modal {
  @apply fixed z-50 h-auto top-auto left-auto bottom-4 right-4 md:bottom-4 xl:bottom-8 md:right-4 xl:right-8;
  @apply w-[calc(100%-2rem)] md:w-auto;
  @apply max-w-[500px];
}
#cookie-modal .cookie-modal__content {
  @apply p-4 md:p-6 pt-3 md:pt-4 m-0 shadow-none;
}
#cookie-modal .cookie-modal__title {
  @apply text-base md:text-lg font-bold;
  @apply mb-2;
}
#cookie-modal .cookie-modal__text {
  @apply mb-4 md:mb-6;
}
#cookie-modal .cookie-modal__option {
  @apply flex mb-2;
}
#cookie-modal .cookie-modal__checkbox {
  @apply border md:border-2 bg-transparent border-white;
  border-width: 1px !important;
}
#cookie-modal .cookie-modal__checkbox ~ .cookie-modal__check svg {
  @apply hidden;
}
#cookie-modal .cookie-modal__checkbox:checked ~ .cookie-modal__check svg {
  @apply block;
}
#cookie-modal .cookie-modal__buttons {
  @apply flex gap-4;
}
#cookie-modal .cookie-modal__button {
  @apply py-2.5 px-5;
  @apply text-sm text-white font-bold;
  @apply border border-white;
  @apply basis-1/2 block text-center;
}
#cookie-modal .cookie-modal__button.hide {
  @apply hidden;
}
#cookie-modal .cookie-modal__label {
  @apply cursor-none;
}
#cookie-modal .cookie-modal__button.primary {
  @apply bg-transparent hover:bg-transparent;
}

.editor {
  @apply text-sm md:text-base;
}
.footer .editor {
  @apply text-xxs md:text-xs;
}
.editor h1 {
  @apply font-extenda text-2xl md:text-3xl lg:text-4xl;
  @apply tracking-wider;
  @apply mb-[0.25em];
}
.editor h2 {
  @apply font-extenda text-2xl md:text-3xl lg:text-4xl;
  @apply tracking-wider;
  @apply mb-[0.25em];
}
.editor h3 {
  @apply text-lg md:text-xl;
}
.editor h4,
.editor h5 {
  @apply text-base md:text-lg;
}
.footer .editor h4,
.footer .editor h5 {
  @apply pt-4;
}
.editor h3,
.editor h4,
.editor h5 {
  @apply font-protokoll;
  @apply font-bold;
  @apply mb-[0.25em];
}
.editor h1,
.editor h2,
.editor h3,
.editor h4,
.editor h5 {
  hyphens: auto;
}
@screen xl {
  .editor h1,
  .editor h2,
  .editor h3,
  .editor h4,
  .editor h5 {
    hyphens: none;
  }
}
.editor-teaser p {
  @apply font-bold;
}
.editor p,
.editor ol,
.editor ul {
  @apply mb-[1em];
}
.editor p:last-child,
.editor ol:last-child,
.editor ul:last-child {
  @apply mb-0;
}
.editor a:not(.no-underline) {
  @apply decoration-1 underline underline-offset-[0.3em] decoration-transparent transition-all hover:decoration-inherit;
}
.editor ol,
.editor ul {
  @apply max-w-3xl mx-auto text-left;
}
.editor ul li {
  @apply mb-1 pl-6 relative;
}
.editor ul li:before {
  content: "— ";
  @apply absolute left-0;
}

.footer--button {
  --circle-radius: 5%;
  --circle-x: 0;
  --circle-y: 0;
}
.footer--button .footer--button-deco {
  clip-path: circle(var(--circle-radius) at var(--circle-x) var(--circle-y));
}

.grid__1-cols {
  @apply md:grid grid-cols-1;
}

.grid__2-cols {
  @apply md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
}

.grid__3-cols {
  @apply md:grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3;
}

.grid__1-cols .grid-col-1 {
  @apply col-span-1;
}
.grid__2-cols .grid-col-1 {
  @apply col-span-1;
}
.grid__3-cols .grid-col-1 {
  @apply col-span-1;
}

.grid__2-cols .grid-col-2 {
  @apply col-span-1 lg:col-span-2;
}
.grid__3-cols .grid-col-2 {
  @apply col-span-1;
}

.grid__2-cols .grid-col-3 {
  @apply col-span-1;
}
.grid__3-cols .grid-col-3 {
  @apply col-span-1;
}

.img-full img,
.img-full picture,
.img-full svg {
  @apply block w-full h-auto;
}

.link-pager-link {
  @apply block w-[44px] h-[44px];
  @apply flex justify-center items-center;
}
.link-arrow {
  display: inline-flex;
  align-items: center;
}
.link-arrow:after {
  content: "";
  display: inline-block;
  width: 0.5em;
  height: 0.5em;
  border: 2px solid #fff;
  border-bottom-color: rgb(255, 255, 255);
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-left-color: rgb(255, 255, 255);
  border-left-style: solid;
  border-left-width: 2px;
  border-left: 0;
  border-bottom: 0;
  transform: rotate(45deg);
  margin-left: 0.4em;
}
.link-arrow span {
  @apply decoration-1 underline underline-offset-[0.3em] decoration-transparent transition-all;
}
.link-arrow:hover span {
  @apply decoration-inherit;
}

.link-expand {
  @apply inline-flex items-center relative;
  @apply pr-10;
}
.link-expand .expand-toggle-label {
  @apply decoration-1 underline underline-offset-[0.3em] decoration-transparent transition-all;
}
.link-expand:hover .expand-toggle-label {
  @apply decoration-inherit;
}
.link-expand .icon-expand-btn,
.link-expand .icon-expand-btn-hvr {
  @apply w-[30px] h-[30px];
  @apply block absolute right-0 top-1/2 -translate-y-1/2 origin-center transition-all;
  @apply rounded-full;
}
.link-expand .icon-expand-btn-hvr {
  @apply hidden;
}
.link-expand:hover .icon-expand-btn, .is-active .link-expand .icon-expand-btn {
  @apply hidden;
}
.link-expand:hover .icon-expand-btn-hvr, .is-active .link-expand .icon-expand-btn-hvr {
  @apply block;
}
.is-active .link-expand .icon-expand-btn-hvr {
  @apply -rotate-180;
}

.linkteaser .button {
  @apply my-[1.5em];
}

.navigation--header-item {
  @apply w-full;
  @apply transition-colors duration-200 bg-transparent xl:hover:bg-black_transparent;
  @apply py-2.5 xl:py-0;
  @apply border-t border-white xl:border-t-0;
}
.navigation--header-item.open {
  @apply xl:bg-black_transparent xl:hover:bg-black_transparent;
}
.navigation--header-item a {
  @apply block px-6 py-2 xl:pl-10;
}
.navigation--header-item .icon-expand-btn,
.navigation--header-item .icon-expand-btn-hvr {
  @apply right-4 xl:right-auto xl:left-2.5 xl:w-[22px] xl:h-[22px];
}
.navigation--header-item .icon-expand-btn-hvr {
  @apply hidden rotate-0 transition-all;
}
.navigation--header-item .navigation--level-2 {
  @apply hidden;
}
.navigation--header-item.open .navigation--level-2 {
  @apply block;
}
.navigation--header-item.open .icon-expand-btn {
  @apply hidden;
}
.navigation--header-item.open .icon-expand-btn-hvr {
  @apply block -rotate-180;
}
.navigation--footer-item {
  @apply text-xxs md:text-xs mb-4;
}
.navigation--trigger {
  @apply z-50 border-0 bg-none p-0 outline-none w-16 h-16;
  @apply text-black;
  @apply transition-opacity opacity-100 duration-200;
}
.navigation__active .navigation--trigger {
  @apply hover:text-white;
}
.scrolling .navigation--trigger {
  @apply opacity-0;
}
.navigation--trigger-deco-01, .navigation--trigger-deco-02, .navigation--trigger-deco-03 {
  content: "";
  @apply block absolute top-5 md:top-6 right-6 h-8 md:h-10 md:right-8 w-[2px] transition-all duration-200;
  @apply bg-white;
}
.navigation--trigger-deco-01 {
  @apply -translate-x-2 origin-bottom-left;
}
.navigation--trigger-deco-03 {
  @apply translate-x-2 origin-bottom-right;
}
.navigation--trigger-deco-01, .navigation--trigger-deco-03, .navigation--trigger-deco-03 {
  @apply rotate-0;
  @apply opacity-100;
}
.navigation__active .navigation--trigger-deco-01 {
  @apply rotate-45 -translate-x-[11px] -translate-y-[5px];
  @apply md:-translate-x-[14px] md:-translate-y-[4px];
}
.navigation__active .navigation--trigger-deco-02 {
  @apply opacity-0;
}
.navigation__active .navigation--trigger-deco-03 {
  @apply -rotate-45 translate-x-[11px] -translate-y-[5px];
  @apply md:translate-x-[14px] md:-translate-y-[4px];
}
.navigation--language li a {
  display: block;
  margin-right: 0.3rem;
  padding-right: 0.3rem;
  line-height: 1;
  border-right: 1px solid white;
}
.navigation--language li:last-child a {
  border-right: none;
}

.tabs {
  @apply p-0;
}
.tabs .invisible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
.tabs .vantage-tabs-tabs__list {
  @apply mt-4 mb-6 md:mb-8 p-0 pl-6;
  @apply xl:flex xl:justify-start items-center overflow-hidden text-ellipsis;
}
.tabs .vantage-tabs-tabs__list li:first-child a {
  @apply pl-0;
}
.tabs .vantage-tabs-tabs__item {
  @apply border-b border-b-white_transparent;
  @apply xl:border-b-0 xl:border-r xl:border-r-white_transparent last:xl:border-r-0;
}
.tabs .vantage-tabs-tabs__link {
  @apply cursor-pointer select-none;
  @apply font-bold py-1.5 xl:py-2 px-4 pl-0 xl:pl-4 bg-none block xl:text-center transition-opacity opacity-50;
}
.tabs [aria-selected=true].vantage-tabs-tabs__link {
  @apply opacity-100;
}
.tabs .vantage-tabs-tabs__content > h3 {
  @apply hidden;
}
.tabs [aria-hidden=true].vantage-tabs-tabs__content {
  @apply hidden;
}