.navigation

  &--header-item
    @apply w-full
    @apply transition-colors duration-200 bg-transparent xl:hover:bg-black_transparent
    &.open
      @apply xl:bg-black_transparent xl:hover:bg-black_transparent
    a
      @apply block px-6 py-2 xl:pl-10


    @apply py-2.5 xl:py-0
    @apply border-t border-white xl:border-t-0

    .icon-expand-btn,
    .icon-expand-btn-hvr
      @apply right-4 xl:right-auto xl:left-2.5 xl:w-[22px] xl:h-[22px]
    .icon-expand-btn-hvr
      @apply hidden rotate-0 transition-all

    .navigation--level-2
      @apply hidden
    &.open
      .navigation--level-2
        @apply block
      .icon-expand-btn
        @apply hidden
      .icon-expand-btn-hvr
        @apply block -rotate-180



  &--footer-item
    @apply text-xxs md:text-xs mb-4

  &--trigger
    @apply z-50 border-0 bg-none p-0 outline-none w-16 h-16

    @apply text-black
    // @apply text-black
    .navigation__active &
      @apply hover:text-white

    @apply transition-opacity opacity-100 duration-200
    .scrolling &
      @apply opacity-0

    &-deco-01,
    &-deco-02,
    &-deco-03
      content: ''
      @apply block absolute top-5 md:top-6 right-6 h-8 md:h-10 md:right-8 w-[2px] transition-all duration-200
      @apply bg-white

    &-deco-01
      @apply -translate-x-2 origin-bottom-left
    &-deco-03
      @apply translate-x-2 origin-bottom-right

    &-deco-01,
    &-deco-03,
    &-deco-03
      @apply rotate-0
      @apply opacity-100

    .navigation__active &
      &-deco-01
        @apply rotate-45 -translate-x-[11px] -translate-y-[5px]
        @apply md:-translate-x-[14px] md:-translate-y-[4px]
      &-deco-02
        @apply opacity-0
      &-deco-03
        @apply -rotate-45 translate-x-[11px] -translate-y-[5px]
        @apply md:translate-x-[14px] md:-translate-y-[4px]



  &--language
    li
      a
        display: block
        margin-right: 0.3rem
        padding-right: 0.3rem
        line-height: 1
        border-right: 1px solid white
      &:last-child
        a
          border-right: none
