\:root
  --vh: 100vh

  --c-white:           #FFFFFF
  --c-block:           #000000

  --c-neutral-light:   #F0F0F4
  --c-border:          #F0F0F4
  --c-neutral-overlay: rgba(0,0,0,0.1)


  --c-blue-100:        #065BEE
  --c-blue-200:        #0343E3
  --c-blue-300:        #012DD6
  --c-blue-400:        #001DC7
  --c-blue-500:        #0013B6
  --c-blue-600:        #000A96


  --c-green-100:       #1F8C33
  --c-green-200:       #177A24
  --c-green-300:       #17661C
  --c-green-400:       #125417
  --c-green-500:       #0A420F
  --c-green-600:       #0A300D


  --c-orange-100:      #FDA112
  --c-orange-200:      #F98613
  --c-orange-300:      #F46B13
  --c-orange-400:      #ED5C17
  --c-orange-500:      #EB540F
  --c-orange-600:      #E84D0A


  --c-purple-100:      #8A45EB
  --c-purple-200:      #6E29DB
  --c-purple-300:      #6321D1
  --c-purple-400:      #591CBF
  --c-purple-500:      #4F17B0
  --c-purple-600:      #4712A1


  --c-teal-100:        #218FA7
  --c-teal-200:        #1A788A
  --c-teal-300:        #125E73
  --c-teal-400:        #0D5063
  --c-teal-500:        #09455A
  --c-teal-600:        #063F56


  --c-red-100:         #E93947
  --c-red-200:         #E02937
  --c-red-300:         #D21D2A
  --c-red-400:         #C41420
  --c-red-500:         #B22719
  --c-red-600:         #9D0913


  --c-neutral-100:         #a7a8aa
  --c-neutral-200:         #97999b
  --c-neutral-300:         #888b8d
  --c-neutral-400:         #75787b
  --c-neutral-500:         #53565a
  --c-neutral-600:         #000000
