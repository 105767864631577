.ac-header
  @apply relative z-0

  .icon-expand-btn,
  .icon-expand-btn-hvr
    @apply right-4 md:right-4

  .icon-expand-btn-hvr
    @apply hidden


  &:hover,
  .is-active &
    .icon-expand-btn
      @apply hidden
    .icon-expand-btn-hvr
      @apply block

  .is-active &
    .icon-expand-btn-hvr
      @apply -rotate-180

  &:focus,
  &::-moz-focus-inner
    border: none
    outline: none !important

// .ac-header
//   .is-active &
//     @apply border-b-transparent

.ac-text
  @apply pb-8

.ac.is-active + .ac
  .ac-header
    @apply border-t-transparent
