@font-face {
  font-family: "Protokoll";
  src: url("/assets/fonts/Protokoll-Regular.woff") format("woff"), url("/assets/fonts/Protokoll-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Protokoll";
  src: url("/assets/fonts/Protokoll-Bold.woff") format("woff"), url("/assets/fonts/Protokoll-Bold.woff2") format("woff2");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Extenda";
  src: url("/assets/fonts/Extenda-30Deca.woff") format("woff"), url("/assets/fonts/Extenda-30Deca.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
